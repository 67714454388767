import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CustomerDetailAllocation } from '../models/customer-detail.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountPerms, CustomerSegments, AllocationStatus, Permissions } from '../models/admin.model';
import { from } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services';
import { APIServiceCodegen, CustomerDetail, MetadataPicklistCriteriaInput, ConfigurableCustomerSegment, CustomerDetailCreateCriteriaInput, MetadataPicklist, CustomerDetailEditCriteriaInput, CustomerDetailCancelCriteriaInput } from 'src/app/API.service';
import { DetailsDialogComponent } from '../details-dialog/details-dialog.component';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { DatePipe, formatDate } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy {
    status: string;
    statusId: AllocationStatus;
    cc_enrollment_code: string;
    facilityRequired = false;
    public accountDetails: CustomerDetail;
    customerSegmentTypes: ConfigurableCustomerSegment[]; // MetadataPicklist[];
    enrollmentChannels: MetadataPicklist[];
    showCancellationItems = false;
    cancelationReasons: MetadataPicklist[];
    public detailsDialogRef: MatDialogRef<DetailsDialogComponent>;
    subscriptionForm: FormGroup = null;
    allocationIndex = 0;
    mode: string;
    action = '';
    accountNumber: string = '';
    areButtonsLoading = false;
    previousPage = 'customer-detail';
    activeDate: Date;
    cancelledDate: Date;
    createdDate: Date;
    public errorDialogRef: MatDialogRef<ErrorDialogComponent>;
    verificationDate = '';
    prevVerificationDate = '';
    isVerificationDateDisabled = false;
    returnAdd = '';
    allocation: CustomerDetailAllocation;
    nullValue = null;
    public get allocationStatus(): typeof AllocationStatus {
        return AllocationStatus;
    }


    constructor(
        public apiService: APIServiceCodegen,
        public datasvc: DataService,
        public route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        public snackBar: MatSnackBar) {
        this.accountDetails = this.router.getCurrentNavigation().extras.state.accountDetails;
        this.allocationIndex = this.router.getCurrentNavigation().extras.state.index;
    }

    ngOnInit() {
        this.onstart()
    }

    onstart() {
        this.route.params.subscribe(params => {
            if (params['From']) {
                if (params['From'] === 'AllocationReport') {
                    this.previousPage = 'allocation-report';
                }
                if (params['From'] === 'EnrolledAllocationsReport') {
                    this.previousPage = 'enrolled-allocations-report';
                }
                else if (params['From'] === 'WaitListReport') {
                    this.previousPage = 'wait-list';
                }
            }
            if (params['ReturnAdd']) {
                this.returnAdd = params['ReturnAdd'];
            }
            this.setForm();
            if (params['Mode']) {
                if (params['Mode'] === 'New') {
                    let permList = this.datasvc.getPermissions();
                    if (!permList.find(num => num == Permissions.AllocationNew)) {
                        this.navigateBack();
                    }
                    this.action = 'New';
                    this.accountNumber = this.accountDetails.account_number;
                } else {
                    if (params['Mode'] === 'Cancel') {
                        this.action = 'Cancel';
                        this.accountNumber = this.accountDetails.account_number;
                        this.showCancellationItems = true;
                        this.pageIsOnCancel();
                    } else if (params['Mode'] === 'Edit') {
                        this.action = 'Edit';
                        this.accountNumber = this.accountDetails.account_number;
                        this.pageIsOnEdit();
                    } else if (params['Mode'] === 'View') {
                        this.action = 'View';
                        this.accountNumber = this.accountDetails.account_number;
                        this.pageIsOnView();
                    } else if (params['Mode'] === 'Transfer') {
                        this.action = 'Transfer';
                        this.accountNumber = '';
                        this.pageIsOnTransfer();
                    }
                }
            }
            console.log('here222');

            from(
                this.apiService.ListMetadataPicklist(
                    <MetadataPicklistCriteriaInput>{ metadata_name: "enrollment_channels" }
                )
            ).pipe(catchError(error => {
                error.errors.forEach(iError => {
                    this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                })
                return of(error)
            })).subscribe((resp: any) => {
                this.enrollmentChannels = <MetadataPicklist[]>resp.metadata_picklist;
            })

            from(
                this.apiService.ListMetadataPicklist(
                    <MetadataPicklistCriteriaInput>{ metadata_name: "cancel_reasons" }
                )
            ).pipe(catchError(error => {
                error.errors.forEach(iError => {
                    this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                })
                return of(error)
            })).subscribe((resp: any) => {
                this.cancelationReasons = <MetadataPicklist[]>resp.metadata_picklist;
            })

            from(
                this.apiService.ListCustomerSegments()
            ).pipe(catchError(error => {
                error.errors.forEach(iError => {
                    this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                })
                return of(error)
            })).subscribe((resp: any) => {
                this.customerSegmentTypes = <ConfigurableCustomerSegment[]>resp
            })
            this.setValuesInForm();
            if (this.datasvc.getAccountPermType() === AccountPerms.LimitedAccess) {
                this.userHasLimitedAccess();
            }
        });
        this.datasvc.subscriptionSizeSelection.subscribe(x => {
            this.subscriptionForm.patchValue({
                subscriptionSize: x
            });
        });
    }

    ngOnDestroy() { }

    onCancelEnrollment() {
        if (!this.subscriptionForm.valid || this.areButtonsLoading) {
            return;
        }
        this.areButtonsLoading = true;

        from(
            this.apiService.CancelSubscription(
                <CustomerDetailCancelCriteriaInput>{
                    account_id: this.accountDetails.account_number,
                    cancel_reason_id: this.subscriptionForm.get('cancelReason').value,
                    allocation_id: this.accountDetails.allocations[this.allocationIndex].allocation_id,
                    account_notes: this.subscriptionForm.get('notes').value,
                    business_partner_id: this.accountDetails.business_partner_id,
                    created_by: this.datasvc.getUserName()
                }
            )
        ).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
            })
            return of(error)
        }))
            .subscribe((resp: any) => {
                this.updateAccountSessionStorage(resp);
                this.areButtonsLoading = false;
                this.navigateBack();
            })
    }

    navigateBack() {
        if (this.returnAdd) {
            this.router.navigate(['admin/' + this.previousPage, { From: this.returnAdd }]);
        } else {
            this.router.navigate(['admin/' + this.previousPage]);
        }
    }

    onVerify(isChecked: boolean) {
        this.verificationDate = isChecked ? formatDate(new Date(), 'M/d/yyyy', 'en-us') : this.prevVerificationDate;
    }

    pipe = new DatePipe('en-US');

    getNoteEnding() {
        if (!this.accountDetails || (this.accountDetails.account_notes != this.subscriptionForm.get('notes').value)) {
            return " - Created " + this.pipe.transform(new Date(), 'short') + " by " + this.datasvc.getUserName();
        } else {
            return "";
        }
    }

    getMaxSubEditNote() {
        if (this.accountDetails.current_allocation_size_kw != this.subscriptionForm.get('subscriptionSize').value) {
            return "Max Subscription Size edited on " + this.pipe.transform(new Date(), 'short') + " by " + this.datasvc.getUserName();
        } else {
            return "";
        }
    }

    getTransferNoteEnding() {
       return " - Created " + this.pipe.transform(new Date(), 'short') + " by " + this.datasvc.getUserName();      
    }

    onEnroll() {
        if (!this.subscriptionForm.valid || this.areButtonsLoading) {
            return;
        }
        let segment = this.customerSegmentTypes.find(x => x.customer_segment_id == this.subscriptionForm.get('customerSegmentType').value)
        if (confirm("Are you sure Customer Segment is currently:  " + segment.customer_segment_description)) {
            this.areButtonsLoading = true;
            from(
                this.apiService.CreateSubscription(
                    <CustomerDetailCreateCriteriaInput>{
                        account_number: this.accountDetails.account_number,
                        allocation_size_kw: this.subscriptionForm.get('subscriptionSize').value,
                        account_notes: this.subscriptionForm.get('notes').value + this.getNoteEnding(),
                        business_partner_id: this.accountDetails.business_partner_id,
                        created_by: this.datasvc.getUserName(),
                        customer_segment_id: this.subscriptionForm.get('customerSegmentType').value + "",
                        enrollment_channel_id: this.subscriptionForm.get('enrollment_channel').value,
                        is_low_income: this.subscriptionForm.get('customerSegmentType').value == CustomerSegments.LowIncome,
                        max_subscription_size_kw: 0,
                        res_non_res: this.accountDetails.is_residential ? 'R' : 'N'
                    }
                )
            ).pipe(catchError(error => {
                error.errors.forEach(iError => {
                    this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                })
                return of(error)
            }))
                .subscribe(resp => {
                    this.updateAccountSessionStorage(resp);
                    this.areButtonsLoading = false;
                    this.navigateBack();
                })
        }
    }

    onTransfer() {
        if (!this.subscriptionForm.valid || this.areButtonsLoading) {
            return;
        }
        this.areButtonsLoading = true;
        console.log('before call');
        from(
            this.apiService.CreateSubscription(
                <CustomerDetailCreateCriteriaInput>{
                    account_number: this.subscriptionForm.get('accountNumber').value,
                    allocation_size_kw: this.subscriptionForm.get('subscriptionSize').value,
                    account_notes: this.subscriptionForm.get('notes').value + this.getTransferNoteEnding(),
                    business_partner_id: this.subscriptionForm.get('businessPartner').value,
                    created_by: this.datasvc.getUserName(),
                    customer_segment_id: this.subscriptionForm.get('customerSegmentType').value + "",
                    enrollment_channel_id: this.subscriptionForm.get('enrollment_channel').value,
                    is_low_income: this.subscriptionForm.get('customerSegmentType').value == CustomerSegments.LowIncome,
                    max_subscription_size_kw: 0,
                    res_non_res: 'R',
                    zpart_month: this.subscriptionForm.get('zMonth').value,
                    zpart_year: this.subscriptionForm.get('zYear').value
                }
            )
        ).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
            })
            this.areButtonsLoading = false;
            return of(error)
        }))
            .subscribe(resp => {
                this.updateAccountSessionStorage(resp);
                this.areButtonsLoading = false;
                this.navigateBack();
            })
    }

    updateAccountSessionStorage(crudResponse: any) {
        let detailedAccountRecord = this.datasvc.getAccountLookupForEmployee();
        if (detailedAccountRecord) {
            detailedAccountRecord.account_notes = crudResponse.account_notes;
            detailedAccountRecord.current_allocation_size_kw = crudResponse.current_allocation_size_kw;
            detailedAccountRecord.wait_list_position = crudResponse.wait_list_position;
            detailedAccountRecord.customer_segment = crudResponse.customer_segment;
            detailedAccountRecord.allocations = crudResponse.allocations;
            this.datasvc.setAccountLookupForEmployee(detailedAccountRecord);
        }
    }

    onUpdate() {
        if (!this.subscriptionForm.valid || this.areButtonsLoading) {
            return;
        }

        this.areButtonsLoading = true;
        from(
            this.apiService.EditSubscription(
                <CustomerDetailEditCriteriaInput>{
                    account_id: this.accountDetails.account_number,
                    allocation_size_kw: this.subscriptionForm.get('subscriptionSize').value,
                    allocation_id: this.accountDetails.allocations[this.allocationIndex].allocation_id,
                    account_notes: this.subscriptionForm.get('notes').value + this.getNoteEnding() + this.getMaxSubEditNote(),
                    business_partner_id: this.accountDetails.business_partner_id,
                    created_by: this.datasvc.getUserName(),
                    new_allocation_status_id: this.subscriptionForm.get('newAllocationStatus')?.value ? this.subscriptionForm.get('newAllocationStatus').value : null
                }
            )
        ).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
            })
            return of(error)
        })).subscribe(resp => {
            this.updateAccountSessionStorage(resp);
            this.areButtonsLoading = false;
            this.navigateBack();
        })
    }

    onCancel() {
        this.areButtonsLoading = true;
        this.navigateBack();
    }

    setForm() {
        this.subscriptionForm = new FormGroup({
            subscriptionSize: new FormControl('', [Validators.required, Validators.min(1), Validators.max(this.validateMaxSubscriptionSize())]),
            customerSegmentType: new FormControl('', Validators.required),
            enrollment_channel: new FormControl('', Validators.required),
            cancelReason: new FormControl('', this.showCancellationItems ? Validators.required : null),
            newAllocationStatus: new FormControl(''),
            notes: new FormControl(''),
            accountNumber: new FormControl(''),
            businessPartner: new FormControl(''),
            zYear: new FormControl(''),
            zMonth: new FormControl('')

        });
    }

    validateMaxSubscriptionSize() {
        if (this.allocationIndex && this.accountDetails.allocations[this.allocationIndex].allocation_status_id == AllocationStatus.Enrolled) {
            return this.accountDetails.allocations[this.allocationIndex].allocation_size_kw;
        } else {
            return null;
        }
    }

    setValuesInForm() { // if allocation exists
        if (this.allocationIndex || this.allocationIndex === 0) {
            this.allocation = this.accountDetails.allocations[this.allocationIndex];
            this.activeDate = this.allocation.active_date ? new Date(this.allocation.active_date) : undefined;
            this.cancelledDate = this.allocation.cancelled_date ? new Date(this.allocation.cancelled_date) : undefined;
            this.createdDate = new Date(this.allocation.created_at);
            this.subscriptionForm.patchValue({
                subscriptionSize: this.allocation.allocation_size_kw,
                customerSegmentType: this.accountDetails.customer_segment.customer_segment_id,
                enrollment_channel: this.allocation.enrollment_channel_id,
                cancelReason: this.accountDetails.allocations[this.allocationIndex].cancel_reason_id,
                notes: this.accountDetails.account_notes
            });
            this.status = this.allocation.allocation_status_description;
            this.statusId = this.allocation.allocation_status_id;
            this.cc_enrollment_code = this.accountDetails.allocations[this.allocationIndex].customer_connect_enrollment_code

            if (this.statusId === AllocationStatus.PendingEnrollment) {
                this.subscriptionForm.controls['subscriptionSize'].disable();
            }
            if (this.statusId === AllocationStatus.Cancelled) {
                this.showCancellationItems = true;
            }
            setTimeout(() => {
                this.subscriptionForm.patchValue({
                    subscriptionSize: this.allocation.allocation_size_kw,
                });
            }, 100)
        } else if (this.action == 'Transfer') {
            this.subscriptionForm.patchValue({
                enrollment_channel: 11
            });
        } else { // if new allocation
            this.subscriptionForm.patchValue({
                customerSegmentType: +this.accountDetails.customer_segment.customer_segment_id,
                notes: this.accountDetails.account_notes
            });
        }
    }

    hasCancelReason() {
        return this.subscriptionForm.get('cancelReason').value ? true : false;
    }

    showViewDetail() {
        this.detailsDialogRef = this.dialog.open(DetailsDialogComponent, {
            id: 'detailsDialog',
            minWidth: '70vw',
            data: {
                account_id: this.accountDetails.account_number,
                subscription_size_kw: this.subscriptionForm.get('subscriptionSize').value,
                customer_segment_id: this.subscriptionForm.get('customerSegmentType').value
            }
        });
    }

    userHasLimitedAccess() {
        this.subscriptionForm.controls['subscriptionSize'].disable();
        this.subscriptionForm.controls['enrollment_channel'].disable();
        this.subscriptionForm.controls['cancelReason'].disable();
    }

    pageIsOnCancel() {
        let permList = this.datasvc.getPermissions();
        if (!permList.find(num => num == Permissions.AllocationCancel)) {
            this.navigateBack();
        }
        this.mode = 'Cancel';
        this.subscriptionForm.controls['subscriptionSize'].disable();
        this.subscriptionForm.controls['enrollment_channel'].disable();
        this.subscriptionForm.controls['customerSegmentType'].disable();
        this.isVerificationDateDisabled = true;
    }

    pageIsOnEdit() {
        let permList = this.datasvc.getPermissions();
        if (!permList.find(num => num == Permissions.AllocationEdit)) {
            this.navigateBack();
        }
        this.subscriptionForm.controls['enrollment_channel'].disable();
        this.subscriptionForm.controls['customerSegmentType'].disable();
    }

    pageIsOnTransfer() {
        let permList = this.datasvc.getPermissions();
        if (!permList.find(num => num == Permissions.WaitListReport)) {
            this.navigateBack();
        }
        this.subscriptionForm.controls['enrollment_channel'].disable();
    }

    pageIsOnView() {
        let permList = this.datasvc.getPermissions();
        if (!permList.find(num => num == Permissions.AllocationView)) {
            this.navigateBack();
        }
        this.mode = 'View';
        this.subscriptionForm.controls['subscriptionSize'].disable();
        this.subscriptionForm.controls['enrollment_channel'].disable();
        this.subscriptionForm.controls['customerSegmentType'].disable();
        this.subscriptionForm.controls['notes'].disable();
        this.subscriptionForm.controls['cancelReason'].disable();
        this.isVerificationDateDisabled = true;
    }

    showError(errors: string[]) {
        const errorList = [];
        errors.forEach(x => {
            errorList.push(x.replace('java.lang.Exception:', ''));
        });
        this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
            id: 'ineligibilityReasonsDialog',
            minWidth: '60vw',
            data: {
                errors: errorList
            }
        });
    }
}
