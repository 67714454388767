const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_G1LBqSC5r",
    "aws_user_pools_web_client_id": "p42ua6nmunu5al1lgj2su7f0",
    "oauth": {
      "domain": "cec-userpooldomain-sbx.auth.us-east-1.amazoncognito.com",
      "scope": [
        "email",
        "profile",
        "openid"
        ],
      "redirectSignIn": "https://cec-admin-sbx.duke-energy.app/",
      "redirectSignOut": "https://cec-admin-sbx.duke-energy.app/",
      "responseType": "token"
    },
    "aws_appsync_graphqlEndpoint": "https://idvp3l5btbhptmxflfct5de5ly.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
